@import '../../../../../Css/variables';

$width: calc(100% / 7);

.calendar {
    box-sizing: border-box;
    font-size: $font-size-10;
    width: $width-100;
    z-index: 0;
    color: $color-white;

    .header {
        padding: $padding-16;
        text-align: center;
        min-height: 2rem;
        line-height: 2rem;
        font-weight: 700;
        display: flex;
        color: $color-primary-4;

        .btn-previous {
            flex: 1;
            text-align: left;
            margin-left: 1rem;

            i {
                font-size: $font-size-16;
                color: $color-primary-4;
            }
        }

        .next {
            flex: 1;
            text-align: right;
            margin-right: 1rem;

            i {
                font-size: $font-size-16;
                color: $color-primary-4;
            }
        }
    }

    .day-names {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        align-items: center;
    }

    .week {
        width: $width;
        height: 44px;
        line-height: 44px;
        text-align: center;
        text-transform: uppercase;
        color: $color-font-secondary-f;
        font-weight: 400;

        div {
            width: 100%;
        }
    }

    .day {
        border-radius: $border-raduis-10;
        border: $border-1;
        width: $width;
        height: 44px;
        display: inline-block;
        padding: 0;
        margin: 0;
        z-index: 1;
        vertical-align: middle;

        div {
            @include flexCenter;
            width: 54px;
            height: 44px;
            color: $color-font-secondary-f;
            font-weight: $font-weight-bold !important;
            z-index: 100;
            line-height: 44px;

            &.before {
                color: $color-primary-4;
                opacity: $opacity-2;
            }

            &.selected {
                background-color: $color-blood;
                color: $color-font-secondary-f;
                font-weight: bold;
            }

            &.today {
                color: $color-font-secondary-f;
                background-color: $color-gold;
            }
        }
    }
}

@media (max-width: $max-width-991) {
    .calendar {
        font-size: $font-size-10;
    }

    .header {
        font-size: $font-size-10;
    }
}
