@import "../../../Css/variables";

.navMenuBar {
  background-color: $color-primary-1;
  height: calc(100% - 60px);
  width: $width-80px;
  position: fixed;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;

  .menuItemsIconText {
    width: $width-100;
    margin: $padding-10 $padding-0 $padding-10 $padding-0;
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #fff;

    i {
      font-size: $font-size-20;
    }

    &:hover .sideIcon {
      color: #288b92;
    }

    span {
      font-size: $font-size-8;
      color: $color-white;
    }
  }

  &-active .sideIcon {
    color: #288b92;
    span {
      color: #288b92;
    }
  }
}

.menuItemsIconsBottom {
  position: absolute;
  bottom: 0;
  width: 100%;

  a {
    display: block;
    text-decoration: none;
    color: #fff;
    i {
      font-size: $font-size-20;
    }
  }
}

@media (max-width: 991px) {
  .navMenuBar {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    bottom: 0;
    z-index: 9999;
  }
  .menuItemsIconText {
    span {
      display: none;
    }
  }
  .menuItemsIconsBottom {
    display: none;
  }
}
