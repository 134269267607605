@import "../../../../Css/variables";

.success {
    // succes step
    .succesBody {
        width: $width-100;
        position: relative;
        display: flex;
        opacity: $opacity-8;

        .succes {
            width: 70%;

            .hr {
                margin: $margin-0 $margin-0 $margin-10 $margin-0 !important;
            }

            &Info {
                padding: $padding-10 $padding-0 $padding-10 $padding-0;

                .right {
                    float: right;
                }
            }
        }

        .succesBox {
            width: 25%;
            height: $height-100;
            position: absolute;
            right: 0;
            padding-top: $padding-10;
            margin-left: $padding-16;
            text-align: center;
            align-items: center;
        }
    }
}
