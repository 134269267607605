@import "../../Css/variables";

// add this code below in your import if you want to hide the leftdropdown
// datalist {
//     display: none !important;
// }

// input::-webkit-calendar-picker-indicator {
//     display: none !important;
// }

.input{
    padding: $padding-16;
    border: $border-2;
    border-radius: $border-raduis-5 !important;
}

@media (max-width: $max-width-991) {
  .input {
    padding: $padding-10;
    border-radius: $border-raduis-3 !important;
  }
}