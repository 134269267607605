@import "../../../Css/variables";

.posts {
    margin-top: $margin-70 !important;
    margin-bottom: $margin-70 !important;
    justify-content: center;

    .form {
        .textArea {
            width: $width-100;
            outline: none;
            border: none;
            padding: $padding-10;
            border-radius: $border-raduis-5 !important;
            background-color: #fff !important;
            color: $color-primary-4 !important;
            border: $border-2;
        }
        .btnPrimary {
            @include button-primary;
            float: right;
            margin-bottom: $margin-20;
        }
    }
}

// mobile and tablet 991
@media (max-width: $max-width-991) {
    .posts {
        margin-top: $margin-20 !important;
        font-size: small !important;
    }
}
