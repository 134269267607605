@import "../../../Css/variables";

.customContainer {
  padding-right: 0;
  padding-top: 60px !important;

  .ulCards {
    list-style: none;
    padding-left: 0 !important;
    margin-top: $margin-30;
  }

  @media (max-width: 1199px) {
    .SearchContainer {
      width: 90% !important;
      display: flex !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .searchControl {
      font-size: 11pt !important;
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $color-primary-back;
  z-index: 9999;
  display: none;
  top: 0;
}

.hide {
  display: none !important;
}

// .filterParent {
// }

@media (max-width: 767px) {
  .customContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .filterParent {
    left: calc(100% - 120px);
  }
  .filterParentCenter {
    width: 400px !important;
    margin-left: -200px !important;
  }
}

@media (min-width: 768px) {
  .customContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 990px) {
  .filterParentCenter {
    position: fixed;
    width: 500px;
    height: 700px;
    top: 50%;
    left: 50%;
    margin-top: -350px;
    margin-left: -250px;
    z-index: 9999;
  }

  .filterParent {
    position: relative;
    top: 30px;
    width: 100px;
    left: calc(100% - 130px);
  }
  .overlay {
    display: block;
  }
}

@media (min-width: 991px) {
  .filterParent,
  .filterParentCenter {
    width: 280px;
    top: 100px;
    height: 700px;
    right: 20px;
    float: right;
    position: fixed;

    .btnAddNew,
    .divIconFilter {
      display: none !important;
    }

    .filterContainer {
      display: block !important;
    }
  }

  .customContainer {
    margin-right: 300px;
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .filterParent,
  .filterParentCenter {
    width: 300px;
    top: 100px;
    height: 700px;
    right: 20px;
    float: right;
    position: fixed;
  }

  .customContainer {
    margin-right: 350px;
    margin-left: 90px;

    .SearchContainer {
      width: 100% !important;
    }
  }
}
