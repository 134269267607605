@import "../../Css/variables";

@mixin input-style($width) {
  width: $width;
  margin: 8px auto;
  border: none !important;
  border-bottom: 3px solid $color-primary-1 !important;
  padding: 5px 10px;
  outline: none;
  background-color: $color-background !important;
  color: $color-font-primary !important;
  justify-content: flex-end;

  &:focus {
    background: $color-primary-3 !important;
  }
}

@mixin btns-style($bg-color, $bg-hover, $width, $font-color) {
  background-color: $bg-color;
  width: $width;
  margin-left: 8px;
  border: none !important;
  &:hover {
    background-color: $bg-hover;
    color: $font-color;
  }
}
.detailsContainer {
  background-color: $color-primary-white !important;
  margin: 50px auto 10px;
  border: $border-1;
  width: 70% !important;
  border-radius: $border-raduis-10 !important;
  padding: $padding-10;
  .imgProfile {
    border-radius: 70%;
    width: 150px;
    height: 150px;
    margin: auto;
    margin-top: 4%;
    cursor: pointer;
  }
  .labels {
    display: inline;
    width: 150px;
    color: $color-primary-6 !important;
    font-weight: bold;
    justify-content: left;
  }
  .inputsTop {
    @include input-style(55%);
  }

  .inputsBottom {
    @include input-style(70%);
  }
}
.containerBtns {
  width: 18% !important;
  margin-right: 15% !important;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2% !important;
  margin-top: 2% !important;
  .btnEdit {
    @include btns-style(
      $color-primary-1,
      $color-primary-white,
      90px,
      $color-primary-1
    );
  }
  .btnCancel {
    @include btns-style(
      $color-primary-6,
      $color-primary-white,
      90px,
      $color-primary-6
    );
  }
}
@media (max-width: 670px) {
  .detailsContainer {
    width: 300px;
    margin: 0;
    margin-left: calc(2.8% + #{$height-navbar-top});
    margin-bottom: 5%;

    .imgProfile {
      border-radius: 70%;
      width: 100px;
      height: 100px;
      margin: auto;
      cursor: pointer;
    }
  }
  .containerBtns {
    margin-left: auto;
    margin-right: 18%;
    margin-bottom: 20px;
    width: 50%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 320px) {
  .detailsContainer {
    width: 300px;
    margin-left: calc(5% + 45px);
  }
}
@media (max-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .detailsContainer {
    width: 480px;
    margin-left: calc(3% + 45px);
    .inputsTop {
      @include input-style(70%);
    }
  }
}
@media (max-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .detailsContainer {
    width: 500px;
    margin-left: calc(15% + 45px);
    .inputsTop {
      @include input-style(70%);
    }
  }
}
@media (max-width: 992px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .detailsContainer {
    width: 800px;
    margin-left: calc(18% + 45px);
    .inputsTop {
      @include input-style(70%);
    }
  }
  .containerBtns {
    margin-left: auto;
    margin-right: 20%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
