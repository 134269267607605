@import "../../../../../../Css/variables";

.steps {

  .card {
    margin-top: $margin-20;
    background-color: $color-primary-white;
    padding: $padding-30;
    border: $border-2;

    // step three
    .logoDonwload {
      border: $border-2;
      border-radius: $border-raduis-5;
      margin: auto !important;
      justify-content: center !important;
      text-align: center !important;

      img {
        width: $width-100;
        height: $width-100;
      }

      .btnResult {
        width: $width-100;
        @include button-secondary;
        box-shadow: none !important;
        .iconDownload {
          padding-left: $padding-5;
        }
      }
    }

    .stepIcon {
      float: right !important;
    }
  }

  .btnDownload {
    @include button-primary;
    float: right;
  }
}

@media (max-width: $max-width-991) {
  .cardBody {
    margin-bottom: $margin-20 !important;
  }

  // step three
  .logoDonwload {
    img {
      width: $width-100;
      height: $width-100;
      .btnResult {
        @include flexCenter();
        width: $width-100;
      }
    }
  }
}
