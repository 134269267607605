.searchbtn {
  .btnSeachCustom {
    @include button-primary();

    float: right;
  }
}
.SearchContainer {
  width: 49%;
  display: flex;
}
.searchControl {
  width: 94% !important;
  display: inline;
}

@media (max-width: 767px) {
  .SearchContainer {
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .searchControl {
    font-size: 11pt !important;
    height: 37px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .SearchContainer {
    width: 80%;
    display: flex;
  }
}
