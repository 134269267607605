@import "../../../Css/variables";

.filterContainer {
  @include cardStyle();
  background-color: $color-primary-1 !important;
  direction: rtl;
  overflow-y: auto;
  width: 100%;
  height: 95%;

  .divIconFilter {
    color: #fff;
    font-size: 2em;
    font-weight: bold;
    display: inline;
    float: $float-right;
    line-height: 0;
    margin-top: $margin-5;
    margin-right: $margin-5;
  }
  h5 {
    float: $float-left;
    color: #fff;
    margin-top: $margin-5;
    margin-left: $margin-10;
  }

  .cr li {
    margin-left: $margin-10;
    margin-right: $margin-10;
  }

  .cr > li:first-child {
    margin-top: 60px;
  }
}
