@import "../../../Css/variables";

datalist {
    display: none !important;
}
/* specifically hide the arrow on focus */
input::-webkit-calendar-picker-indicator {
    display: none !important;
}

.employees {
    margin-top: $margin-70 !important;
    .inputSearch {
        display: flex;
        margin-bottom: $margin-70;
        .iconSearch {
            padding: $padding-16;
            background-color: $color-primary-white !important;
            border: $border-2;
            border-radius: $border-raduis-5;
            color: $color-hr;
        }
    }
}

@media (max-width: $max-width-991) {
    .employees {
        margin-top: $margin-20 !important;
        margin-bottom: $margin-50 !important;
        font-size: small !important;
        .inputSearch {
            margin-bottom: $margin-30;
            .iconSearch {
                padding: $padding-10;
                border-radius: $border-raduis-3;
            }
        }
    }
}
