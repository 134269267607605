@import "../../../Css/variables";

.Mycard {
  .btn-option-member {
    position: absolute;
    right: 0px;
    top: 0px;

    .items {
      padding: $padding-10;
      background-color: $color-primary-back !important;
      .item {
        cursor: pointer;
        &:hover {
          background-color: $color-primary-1;
          color: $color-primary-white;
        }
      }
    }
  }
  @include cardStyle();

  position: relative;
  margin-bottom: $padding-30;
  border-radius: 5px !important;

  p {
    font-weight: bold;
    margin-bottom: 0;
    color: #212529 !important;
  }

  .iconDiv {
    color: #212529;
    font-size: 2em;
    font-weight: bold;
    position: absolute;
    right: 10px;
  }

  ul {
    list-style: none;
    margin-right: $margin-30;
    margin-left: $margin-30;
    padding-left: 0;
  }

  hr {
    color: #212529;
    margin: 0 0 20px 0 !important;
  }
}
