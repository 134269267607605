@import "../../Css/variables";

.mainContainer {
    margin-left: $margin-8-perce !important;
}

// for mobile
@media (max-width: $max-width-991) {
    * {
        font-size: $font-size-10;
    }

    .mainContainer {
        margin: $margin-0 !important;
    }
}
