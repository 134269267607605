@import "../../../Css/variables";

.statistical {
    @include margin-top-bottom-80;
    @include flexCenter;
    display: block;
    padding-right: 3% !important;

    .line {
        display: flex !important;
        @include flexCenter;
        @include optionItems();
        .btnFilter {
            @include flexCenter;
            display: flex;
        }
        .lineHr {
            float: left !important;
            width: $width-100 !important;
            padding: 0px !important;
            margin: 0px !important;
        }
    }

    .inputSearch {
        display: flex;
        margin-top: $margin-30;
        margin-bottom: $margin-20;
        .iconSearch {
            padding: $padding-16;
            background-color: $color-primary-white !important;
            border: $border-2;
            border-radius: $border-raduis-5;
            color: $color-hr;
        }
    }

    .btnRight {
        @include button-primary;
        float: right;
        display: flex;
        margin-bottom: $margin-16 !important;
        .icon {
            padding-left: $padding-16;
        }
    }
    .map {
        padding-top: $padding-16;
        margin-top: $margin-80 !important;
        height: 70vh;
        width: $width-100;
    }
    .descriptionChart {
        background-color: $color-primary-white;
        padding: $padding-16;
        display: flex;
        .discription {
            text-align: left;
            justify-self: right;
            width: $width-100;
            margin-bottom: $margin-20;
            background-color: $color-primary-white;
            padding: $padding-16;
            border: $border-2;
            border-radius: $border-raduis-5;
            margin-right: $margin-16;
        }
    }

    .charts {
        display: flex;
        width: $width-100;
        .chartOne,
        .chartTwo,
        .chartThree {
            flex-wrap: wrap;
            margin-top: $margin-70;
            background-color: $color-primary-white;
            width: $width-50 !important;
            margin-right: $margin-5;
            padding: $padding-16;
        }
    }
}

@media (max-width: $max-width-991) {
    .statistical {
        display: block !important;
        padding-left: 3% !important;
        .inputSearch {
            margin-top: $margin-20;
        }
        .descriptionChart {
            display: block !important;
            .discription {
                text-align: justify;
                justify-self: center;
                width: $width-100;
                float: none;
                 margin-right: $margin-0;
            }
        }

        .charts {
            flex-wrap: wrap !important;
            width: $width-100 !important;
            .chartOne,
            .chartTwo,
            .chartThree {
                flex-wrap: wrap;
                width: $width-100;
                margin-right: $margin-0;
                margin-top: $margin-20;
            }
        }
    }
}
