.search {
  @media (max-width: 767px) {
    .colButton {
      margin-bottom: 30px !important;
      padding-right: 0 !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .colButton {
      padding-right: 0 !important;
    }

    .colsearch {
      padding-left: 0 !important;
    }
    .SearchContainer {
      width: 100% !important;
      display: flex;
    }
  }

  @media (min-width: 992px) {
    .SearchContainer {
      width: 80% !important;
      display: flex;
    }
  }
}
