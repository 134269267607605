@import "../../../../Css/variables";

//style of card
.customCardBloodst {
  // background-color: $color-primary-1 !important;
  padding: $padding-5 $padding-20 $padding-5 $padding-20;

  //style of dotes
  .costumIconBloodst {
    color: #fff;
    font-size: $font-size-5;
    display: inline !important;
    float: $float-left;
  }

  //style progress
  .customProgress {
    width: 80% !important;
    float: $float-right;

    .progress-bar {
      background-color: $color-blood !important;
    }
  }

  //style of each parent of dot and progress
  .customParent {
    width: 100%;
    display: inline-block;
    margin-bottom: $margin-20;
  }
}
