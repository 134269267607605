@import "../../../Css/variables";

.team {
    @include margin-top-bottom-80;
    @include flexCenter;
    display: block;
}

@media (max-width: $max-width-991) {
    .team {
        margin: $margin-0;
        width: $width-100;
    }
}
