@import "../../../Css/variables";

.add {
    margin-top: $margin-70 !important;
    margin-bottom: $margin-70 !important;

    .title {
        text-align: center;
    }

    input {
        background-color: $color-primary-back;
        border: none !important;
    }

    .form {
        margin: auto !important;
        padding: $padding-5 !important;
        margin-right: 10px !important;
        background-color: $color-primary-white;
        padding: $padding-50 !important;
        border: $border-2;
        border-radius: $border-raduis-5;

        .inputGroup {
            padding-bottom: $padding-16 !important;
        }

        .input {
            padding: $padding-16 !important;
            border: none !important;
            background-color: $color-primary-back;
        }

        .btnSubmit {
            @include button-primary();
        }
    }
}

@media (max-width: $max-width-991) {
    .add {
        margin-top: $margin-20 !important;
        font-size: small !important;

        .form {
            padding: $padding-5 !important;
            margin-right: 0px !important;
            padding: $padding-20 !important;

            .inputGroup {
                padding-bottom: $padding-10 !important;
            }
        }
    }
}
