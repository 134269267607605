@import "../../../../../Css/variables";

.deriCardContainer {
  .directorInfo {
    padding: 16px !important;
    hr {
      margin-right: 25px !important;
    }
  }
  .btn-option-member {
    position: absolute;
    right: 0px;
    top: 0px;

    .items {
      padding: $padding-10;
      background-color: $color-primary-back !important;
      .item {
        cursor: pointer;
        &:hover {
          background-color: $color-primary-1;
          color: $color-primary-white;
        }
      }
    }
  }
  .Mycard {
    padding: 0 !important;
    ul {
      margin: 0 !important;
      padding-left: 0;
    }
  }

  .Mycard .iconDiv {
    z-index: 9999;
  }
  @media (max-width: 767px) {
    .Mycard {
      padding: 0 !important;
    }

    .Mycard ul li:first-child {
      margin-top: 5px !important;
    }

    .Mycard ul li:last-child {
      margin-bottom: 5px !important;
    }

    .Mycard ul {
      margin-right: 5px;
      margin-left: 5px;
      padding-left: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .Mycard ul li:first-child {
      margin-top: 5px !important;
    }

    .Mycard ul li:last-child {
      margin-bottom: 5px !important;
    }

    .Mycard ul {
      margin-right: 5px;
      margin-left: 5px;
      padding-left: 0;
    }
  }
}
