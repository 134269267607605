@import "../../../../Css/variables";

.btnReset {
  margin-top: $margin-16;
  button {
    width: $width-50;
    float: right !important;
    margin: auto !important;
    @include button-secondary();
    box-shadow: none !important;
    border: $border-2 !important;
  }
}

.divProfile {
  margin-top: $margin-30 !important;
  .btnEditProfile {
    @include button-secondary;
    margin-top: $margin-16;
    margin-bottom: $margin-10;
  }
  .editForm {
    // padding: 10px 10px !important;
    .cardFormContainer {
      padding: 15px 15px 15px 15px !important;
      margin: auto;
      background-color: $color-white !important;
      margin-top: $margin-16;
      width: 70%;
      margin-bottom: $margin-10;
      .fields {
        display: $display-flex;
        margin-bottom: $margin-10;
        .labelFields {
          display: inline-block;
          width: 206px;
          color: $color-primary-4;
        }
      }
    }
    .btnFields {
      display: flex;
      width: 50%;
      margin: auto;
      margin-top: 10px;
      // margin-right: 20px;
      Button {
        @include button-secondary;
        width: 55%;
        margin-right: 15px;
      }
    }
  }
}
.divReport {
  // margin-top: 20px;
  .cardReport {
    width: $width-70;
    background-color: $color-white !important;
    margin: auto;
    margin-top: $margin-30;
    border-radius: $border-raduis-10;
    box-shadow: $box-shadow-1;

    Button {
      @include button-secondary;
      width: 10em;
      margin-left: auto;
      margin-top: $margin-5;
      margin-right: 10px;
    }
    .labelReport {
      color: $color-primary-4 !important;
      display: inline-block;
      width: 13em;
      margin-left: $margin-10;
    }
    .infoReport {
      margin-top: $margin-5;
      color: $color-primary-4 !important;
    }
    .dateDelivration {
      margin-left: auto;
      margin-right: $margin-10;
      margin-top: $margin-10;
      color: $color-primary-4 !important;
    }
    hr {
      margin-top: $margin-5;
      margin-bottom: $margin-0;
      color: $color-primary-4 !important;
    }
  }
}
// @media (max-width: 1900px) {
//   .div-profile {
//     .btn-edit-profile {
//       margin-left: 20px;
//     }
//     .edit-form {
//       .card-form-container {
//         width: 58em;
//       }
//       .btn-fields {
//         margin-left: 44em;
//         display: $display-flex;
//         Button {
//           width: 13em;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 1400px) {
//   .div-profile {
//     .btn-edit-profile {
//       margin-left: 20px;
//     }
//     .edit-form {
//       .card-form-container {
//         width: 48em;
//       }
//       .btn-fields {
//         margin-left: 25em;
//         display: $display-flex;
//         Button {
//           width: 13em;
//         }
//       }
//     }
//   }
// }

@media (max-width: 991px) {
  .profile-container {
    margin-left: 0px;
  }
  .div-profile {
    .btn-edit-profile {
      margin-left: 20px;
    }
    .edit-form {
      .card-form-container {
        width: 45em;
      }
      .btn-fields {
        margin-left: 16em;
        display: $display-flex;
        margin-bottom: 5em;
        Button {
          width: 13em;
        }
      }
    }
  }
  .divReport {
    .cardReport {
      width: 90%;
      .labelFields {
        display: inline-block;
        width: 20px !important;
      }
    }
  }
}
// @media (max-width: 768px) {
//   .profile-container {
//     margin-left: 0px;
//   }
//   .div-profile {
//     .btn-edit-profile {
//       margin-left: 20px;
//     }
//     .edit-form {
//       .card-form-container {
//         width: 38em;
//         .fields {
//           display: block;
//         }
//       }
//       .btn-fields {
//         margin-left: 10em;
//         display: $display-flex;
//         margin-bottom: 5em;
//         Button {
//           width: 13em;
//         }
//       }
//     }
//   }
//   .div-report {
//     .card-report {
//       width: 38em;
//     }
//   }
// }

@media (max-width: 576px) {
  .divProfile {
    margin-top: 20px;
    .btnEditProfile {
      margin-left: 10px;
    }
    .editForm {
      .legendSection {
        margin-left: 20px;
      }
      .cardFormContainer {
        // padding: 15px 15px 15px 15px !important;
        // margin: auto;
        // background-color: $color-white !important;
        margin-top: $margin-16;
        width: 90%;
        margin-bottom: $margin-10;
        .fields {
          display: $display-flex;
          margin-bottom: $margin-10;
          .labelFields {
            display: inline-block;
            width: 206px;
            color: $color-primary-4;
          }
        }
      }
      .btnFields {
        display: flex;
        width: 50%;
        margin: auto;
        margin-top: 10px;
        // margin-right: 20px;
        margin-bottom: 20px;
        Button {
          @include button-secondary;
          width: 55%;
          margin-right: 15px;
        }
      }
    }
  }
}
