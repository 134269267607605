@import "../../../Css/variables";

.imgD {
  @include flexCenter;
}

.imgD Image {
  max-width: 100%;
  display: block;
}

@media (max-width: 991px) {
  .Mycard {
    padding: 0 !important;
  }

  .ImageDiv {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .imgD {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #fff !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (min-width: 992px) {
  .ImageDiv {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .imgD {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #fff !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
