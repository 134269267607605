@import "../../../Css/variables";

.home {
  //add space between rows
  .HomeRow {
    margin-bottom: 50px !important;
    height: 30% !important;
  }

  .homePage .row:first-child {
    margin-top: 30px !important;
  }
  .pInfo {
    text-align: center;
    margin-left: $margin-30;
  }

  @media (max-width: 767px) {
    .pInfo {
      margin-left: 0 !important;
    }
    .container {
      padding-left: 20px !important;
      padding-right: 20px !important;
      width: 100% !important;
      max-width: none !important;
    }

    .homePage .row:first-child {
      margin-bottom: 20px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .pInfo {
      margin-left: 0 !important;
    }

    .container {
      padding-left: 40px !important;
      padding-right: 40px !important;
      width: 100% !important;
      max-width: none !important;
    }

    .homePage .row:first-child {
      margin-bottom: 20px !important;
      margin-top: 20px !important;
    }
  }
}
