@import "../../../../Css/variables";

.navbar-register1 {
  background-color: $color-primary-white;
  height: 60px;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 1; 
  .App-logo-register1 {
    height: 6vmin !important;
    pointer-events: none;
    background-color: honeydew;
    border-radius: $border-raduis-10;
    width: 55px;
    height: 50px;
    margin-right: $margin-10;
    margin-left: $margin-10;
  }
  .nv-brand {
    color: $color-primary-white !important;
    background-color: $color-primary-1 !important;
  }
  .navFormRegister1 {
    display: $display-flex !important;
    margin-left: auto !important;
    margin-right: $margin-10;
    .langue {
      color: $color-primary-3 !important;
    }
    #basic-nav-dropdown{
      color: $color-primary-3;
    }
    .btn-login-register1 {
      @include button-primary;
      background-color: $color-primary-7 !important;
    }
  }
}

.navbar-register2 {
  background-color: $color-primary-1 !important;
  height: 60px;
  position: sticky !important;
  top: 0;
  z-index: 1;
  .App-logo {
    height: 6vmin !important;
    pointer-events: none;
    background-color: honeydew;
    border-radius: $border-raduis-10;
    width: 55px;
    margin-right: $margin-10;
    margin-left: $margin-10;
    height: 50px;
  }
  .nv-brand {
    color: $color-primary-2 !important;
    background-color: $color-primary-1;
  }
  .nav-from-register2 {
    align-items: center;
    margin-left: auto;
    margin-right: $margin-10;
    #basic-nav-dropdown {
      color: $color-primary-3;
    }
  }
}

@media (max-width: 991px) {
  .navbar-register1 {
    .App-logo-register1 {
      height: 5vmin !important;
    }
    .nv-brand {
      color: $color-primary-2 !important;
      // text-align: center !important;
    }
    .collapse {
      background-color: $color-primary-1;
      .navFormRegister1 {
        display: flex;
        flex-direction: column;

        .btn-login-register1 {
          width: 100% !important;
          margin-bottom: 5px;
          margin-left: 5px;
        }
      }
    }
  }
  .navbar-register2{
    .App-logo{
      height: 5vmin !important;
    }
    .nv-brand{
      color: $color-primary-2 !important;
      // text-align: center !important;
      margin-left: 23em !important;

    }
  }
}

@media (max-width: 576px) {
  .navbar-register1 {
    .App-logo-register1 {
      height: 45px !important;
    }
    .nv-brand {
      color: $color-primary-2 !important;
      // text-align: center !important;
    }
  }
  .navbar-register2{
    .App-logo{
      height: 45px !important;
    }
    .nv-brand{
      margin-left: 5em !important;
    }
  }
}