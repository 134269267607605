@import "../../../../Css/variables";

.homePage {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: $padding-16 !important;

  .div-info1-home {
    display: $display-flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $margin-70;
    padding-right: 10px;
    padding-left: 10px;
    .div-info1-bar {
      width: 45% !important;
      height: 30% !important;
      margin-right: $margin-50;
      background-color: $color-primary-3;
      box-shadow: $box-shadow-1;
      border-radius: 10px;
      padding: $padding-20;
    }
    .div-info1-description {
      padding: $padding-20;
      margin-left: $margin-50;
      width: 35em;
    }
  }

  .div-info2-home {
    display: $display-flex;
    justify-content: space-around;
    align-items: center;
    margin-top: $margin-30;
    padding-right: 10px;
    padding-left: 10px;
    .div-info2-description {
      margin-right: $margin-50;
      width: 35em;
      padding: $padding-20;
    }
    .div-info2-doughnut {
      width: 20em;
      margin-top: $margin-20;
      background-color: $color-primary-3;
      box-shadow: $box-shadow-1;
      border-radius: 10px;
      margin-left: $margin-50;
      padding: $padding-20;
      p {
        text-align: center;
      }
    }
  }

  .div-info3-home {
    display: $display-flex;
    justify-content: space-around;
    align-items: center;
    margin-top: $margin-30;
    padding-right: 10px;
    padding-left: 10px;
    .div-info3-line {
      width: 45% !important;
      height: 30% !important;
      margin-top: $margin-20;
      background-color: $color-primary-3;
      box-shadow: $box-shadow-1;
      border-radius: 10px;
      margin-right: $margin-50;
      padding: $padding-20;
      p {
        text-align: center;
      }
    }
    .div-info3-description {
      margin-left: $margin-50;
      width: 30em;
      padding: $padding-20;
    }
  }

  .div-info4-home {
    display: $display-flex;
    justify-content: space-around;
    align-items: center;
    margin-top: $margin-30;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 3em;
    .div-info4-doughnut {
      width: 20em;
      margin-top: $margin-20;
      margin-left: $margin-50;
      background-color: $color-primary-3;
      box-shadow: $box-shadow-1;
      border-radius: 10px;
      padding: $padding-20;
      p {
        text-align: center;
      }
    }
    .div-info4-description {
      width: 35em;
      margin-right: $margin-50;
      padding: $padding-20;
    }
  }
}

@media (max-width: 991px) {
  .homePage {
    .div-info1-home {
      display: $display-flex;
      flex-direction: column;
      .div-info1-bar {
        width: 30em;
        height: 17em;
        margin-left: 95px;
        padding: $padding-5;
      }
      .div-info1-description {
        width: 30em;
        padding: $padding-5;
        margin-left: 40px;
        text-align: justify;
      }
    }

    .div-info2-home {
      display: $display-flex;
      flex-direction: column-reverse;
      .div-info2-description {
        width: 30em;
        margin-left: 95px;
        padding: $padding-5;
        text-align: justify;
      }
      .div-info2-doughnut {
        margin-left: 35px;
        padding: $padding-5;
        margin-top: 0px;
        width: 30em;
        p {
          text-align: center;
        }
      }
    }
    .div-info3-home {
      display: $display-flex;
      flex-direction: column;
      .div-info3-description {
        padding: $padding-5;
        text-align: justify;
        margin-left: 45px;
        width: 30em;
      }
      .div-info3-line {
        margin-left: 95px;
        padding: $padding-5;
        margin-top: 0px;
        width: 30em;
        p {
          text-align: center;
        }
      }
    }

    .div-info4-home {
      display: $display-flex;
      flex-direction: column-reverse;
      .div-info4-description {
        margin-left: 95px;
        padding: $padding-5;
        text-align: justify;
        width: 30em;
      }
      .div-info4-doughnut {
        margin-left: 35px;
        padding: $padding-5;
        margin-top: 0px;
        width: 30em;
        p {
          text-align: center;
        }
      }
    }
  }
}
