@import "../../../../Css/variables";

.homePageHeader {
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
  margin-top: -50px !important;
  .navbar {
    background-color: $color-primary-1 !important;
    height: 60px;
    padding: 5px 5px;
    .App-logo {
      height: 6vmin !important;
      width: 55px;
      pointer-events: none; 
      background-color: honeydew;
      border-radius: $border-raduis-10;
      margin-right: $margin-10;
      margin-left: $margin-10;
    }
    .nv-brand {
      color: $color-primary-2;
      background-color: $color-primary-1;
    }
    .collapse {
      background-color: $color-primary-1;
      .frmlog {
        display: $display-flex;
        align-items: center;
        margin-left: auto;
        margin-right: $margin-10;
        text-align: center;
        #basic-nav-dropdown {
          color: $color-primary-2;
        }
        .btn {
          @include button-primary;
          background-color: $color-hover-bg !important;
          height: 50px;
        }
        .btncreate {
          @include button-secondary;
          text-decoration-line: none;
          height: 50px;
          margin-left: 10px;
          border-radius: $border-raduis-3;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .homePageHeader {
    position: sticky !important;
    top: 0;
    z-index: 1;
    .navbar {
      background-color: $color-primary-1 !important;
      height: 60px;
      padding: 5px 5px;
      .App-logo {
        height: 6vmin !important;
        width: 55px;
        pointer-events: none;
        background-color: honeydew;
        border-radius: $border-raduis-10;
        margin-right: $margin-10;
        margin-left: $margin-10;
      }
      .nv-brand {
        color: $color-primary-2;
        background-color: $color-primary-1;
      }
      .collapse {
        background-color: $color-primary-1;
        .frmlog {
          display: flex;
          flex-direction: column;
          .btn {
            background-color: $color-primary-7 !important;
            height: 40px !important;
            width: 100%;
            margin-left: 10px;
            text-align: center !important;
            margin-bottom: $margin-3;
          }
          .btncreate {
            text-decoration-line: none;
            height: 40px !important;
            margin-left: 10px;
            width: 100%;
            margin-bottom: $margin-3;
          }
        }
        .container {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .homePageHeader {
    .navbar {
      .App-logo {
        height: 45px !important;
      }
    }
  }
}
