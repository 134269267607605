@import "../../../Css/variables";

.addCenterForm {
  padding: $padding-30 !important;
  margin-top: $margin-30;
  padding-bottom: 5px !important;
  width: 70%;
  height: 50%;
  margin: 10px auto;

  .form-control,
  .form-select {
    background-color: $color-primary-back !important;
    color: #212529 !important;
  }

  .form-group {
    margin-bottom: $margin-20 !important;
  }

  &-header {
    opacity: 0.8;
    padding-left: $padding-16;
  }

  &-body {
    padding: $padding-16 !important;
  }
  hr {
    height: 1px !important;
    margin: $margin-16 $margin-16 $margin-5 $margin-16 !important;
    background-color: #212529 !important;
  }

  .btnAdd {
    @include button-primary();
    min-width: 100px !important;
  }
}
