@import "../../../Css/variables";

.homePage {
  @include margin-top-bottom-80;

  margin-right: 3%;
  .selectButton {
    .inputSearch {
      display: flex;
      margin-bottom: $margin-30;
      .iconSearch {
        padding: $padding-16;
        background-color: $color-primary-white !important;
        border: $border-2;
        border-radius: $border-raduis-5;
        color: $color-hr;
        cursor: pointer;
      }
    }

    .btnAppointment {
      @include button-primary;
      .icon {
        padding-left: $padding-5;
      }
    }
  }
}

@media (max-width: $max-width-991) {
  .selectButton {
    padding-left: $padding-10 !important;

    .inputSearch {
      margin-bottom: $margin-20;
      .iconSearch {
        padding: $padding-10;
        border-radius: $border-raduis-3;
      }
    }
  }
}
