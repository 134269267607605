@import "../../Css/variables";

.mainform {
  width: 75%;
  height: 600px;
  padding: 35px;
  border-radius: $border-raduis-10;
  margin: 100px 100px 20px auto;
  background-color: $color-primary-white !important;

  .FormLabel {
    color: $color-primary-1 !important;
    display: inline;
    left: 0;
  }

  .FormControl {
    padding: auto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: inline;
    border-radius: $border-raduis-10;
    margin-top: 2%;
    color: black;
    background-color: $color-background !important;
    &:focus {
      background-color: $color-primary-6;
    }
    &:hover {
      background-color: $color-primary-6 !important;
    }
  }

  .FormControlColumn {
    padding: auto;
    width: 38%;
    margin-left: auto;
    margin-right: auto;
    display: inline;
    border-radius: 50px;
    margin-top: 1%;
  }
  .validicon {
    color: $color-primary-1 !important;
    margin-left: 15px;
    display: inline;
  }

  .send {
    width: 44px;
    height: 24px;
    background-color: $color-primary-6 !important;
    font-size: 10px;
    margin-left: -49px;
    margin-top: 0;
    border: none;

    &:hover {
      background-color: $color-primary-4 !important;
    }
  }
}
.add {
  width: $width-80px;
  height: $height-navbar-top;
  background-color: $color-primary-1 !important;
  border-radius: $border-raduis-10;
  margin-right: -50px;
  border: none !important;
  @include button-primary;
  margin-bottom: 2%;
}
@media (max-width: 320px) {
  .mainform {
    width: 100%;
    height: auto;
    padding: 35px;
    margin: 100px 100px 20px auto;

    .FormLabel {
      left: 0;
    }

    .FormControl {
      padding: auto;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: inline;
      margin-top: 2%;
    }

    .FormControlColumn {
      padding: auto;
      width: 38%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1%;
    }
    .validicon {
      margin-left: 15px;
      display: inline;
    }

    .send {
      width: 44px;
      height: 24px;
      font-size: 10px;
      margin-left: -49px;
      margin-top: 0;
    }
  }
  .add {
    width: $width-80px;
    height: $height-navbar-top;
    margin-right: -50px;
    margin-bottom: 2%;
  }
}
@media (max-width: 481px) {
  .mainform {
    width: 100%;
    height: auto;
    padding: 35px;
    margin: 100px 100px 20px auto;

    .FormLabel {
      left: 0;
    }

    .FormControl {
      padding: auto;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      display: inline;
      margin-top: 2%;
      color: $color-font-primary;
    }

    .FormControlColumn {
      padding: auto;
      width: 50%;
      margin-left: -10px;
      margin-top: 3%;
    }
    .validicon {
      margin-left: 5px;
      display: inline;
    }

    .send {
      width: 35px;
      height: 24px;
      font-size: 10px;
      margin-left: -39px;
      margin-top: 0;
      padding: auto;
    }
  }
  .add {
    width: $width-80px;
    height: $height-navbar-top;
    margin-right: -25px;
    margin-bottom: 2%;
  }
}
@media (max-width: 670px) {
}
@media (max-width: 992px) {
}
