@import "../../../Css/variables";

.lockCenter {
    @include margin-top-bottom-80;
    @include flexCenter;
    display: block;
    padding-right: 3% !important;
    display: block !important;

    .inputSearch {
        display: flex;
        margin-bottom: $margin-30;
        .iconSearch {
            padding: $padding-16;
            background-color: $color-primary-white !important;
            border: $border-2;
            border-radius: $border-raduis-5;
            color: $color-hr;
            cursor: pointer;
        }
    }

    .btnRight {
        @include button-primary;
        float: right;
        display: flex;
        .icon {
            padding-left: 16px;
        }
    }
    .map {
        padding-top: $padding-30;
        margin-top: $margin-80 !important;
        height: 70vh;
        width: $width-100;
    }
    .descriptionMap1 {
        text-align: justify;
        justify-self: right;
        width: $width-50;
        float: left;
        margin-top: $margin-70 !important;
        margin-bottom: 20px;
        background-color: $color-primary-white;
        padding: $padding-16;
        border: $border-2;
        border-radius: $border-raduis-5;
    }

    .charts {
        display: flex;
        width: $width-100;
        .chartOne,
        .chartTwo,
        .chartThree {
            flex-wrap: wrap;
            margin-top: $margin-70;
            background-color: $color-primary-white;
            width: $width-50 !important;
            margin-right: $margin-5;
            padding: $padding-16;
        }
    }
}

@media (max-width: $max-width-991) {
    .lockCenter {
        display: block;
        padding-left: 3% !important;

        .inputSearch {
            margin-bottom: $margin-20;
            .iconSearch {
                padding: $padding-10;
                border-radius: $border-raduis-3;
            }
        }

        .map {
            margin-top: $margin-50 !important;
            width: $width-100;
        }

        .descriptionMap1 {
            text-align: justify;
            justify-self: center;
            width: $width-100;
            float: none;
            margin-top: $margin-70 !important;
        }

        .charts {
            width: $width-100;
            flex-wrap: wrap;
            .chartOne,
            .chartTwo,
            .chartThree {
                width: $width-100;
                margin-right: $margin-0;
            }
        }
    }
}
