@import '../../../Css/variables';

.emergency {
    margin-top: $margin-70 !important;
    width: $width-100 !important;
    display: block;
    width: $width-100 !important;
    position: relative;
    opacity: $opacity-8;
    padding: 0 !important;

    .title {
        text-align: center;
    }

    .form {
        margin: auto !important;
        padding: $padding-5 !important;
        margin-right: 10px !important;
        .inputGroup {
            padding-bottom: $padding-16 !important;
        }

        .input {
            padding: $padding-16 !important;
            border: $border-2;
        }

        .btnSubmit {
            @include button-primary();
            float: right;
        }
    }
}

@media (max-width: $max-width-991) {
    .emergency {
        margin-top: $margin-30 !important;
        margin-bottom: $margin-50 !important;
        font-size: small !important;

        .form {
            padding: $padding-5 !important;
            margin-right: 0px !important;
            .inputGroup {
                padding-bottom: $padding-10 !important;
            }
        }
    }
}
