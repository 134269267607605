@import "../../../../../Css/variables";

.members {
    margin-top: $margin-top-6 !important;
    justify-content: center;

        .inputSearch {
            display: flex;
            margin-bottom: $margin-70;
            .iconSearch {
                padding: $padding-16;
                background-color: $color-primary-white !important;
                border: $border-2;
                border-radius: $border-raduis-5;
                color: $color-hr;
                cursor: pointer;
            }
        }

    .addMember {
        z-index: 0;
        margin-bottom: $margin-16;
        float: right;
        @include button-primary;

        &:focus {
            @include button-primary;
        }
    }

    .card {
        width: $width-100;
        .avatar {
            .navItem {
                list-style: none !important;
            }

            .memberUsername {
                margin: $margin-16 $margin-16 $margin-0 $margin-16 !important;
                img {
                    border-radius: $border-raduis-10;
                    width: $width-100 !important;
                }
            }
        }
        .memberInfo {
            .btnOptionMember {
                position: absolute;
                right: 0px;
                top: 0px;
                padding: $padding-5 $padding-5;
                @include optionItems();

                .iconH {
                    display: none;
                }
                .iconV {
                    color: $color-hr;
                }
            }
            .cardBodyItems {
                margin-bottom: $margin-20 !important;
                .hr {
                    margin: $margin-0 $margin-0 $margin-20 $margin-0 !important;
                    padding: 0px !important;
                    border: $border-2;
                }
            }
        }
    }
}

// mobile 991
@media (max-width: $max-width-991) {
    .members {
        .inputSearch {
            margin-bottom: $margin-30;
            .iconSearch {
                padding: $padding-10;
                border-radius: $border-raduis-3;
            }
        }

        .card {
            width: $width-100 !important;

            .memberInfo {
                .btnOptionMember {
                    margin-right: $margin-5;
                    .iconV {
                        display: none;
                    }
                    .iconH {
                        display: unset;
                        color: $color-hr;
                    }
                }
            }
            .avatar {
                .memberUsername {
                    img {
                        margin-top: 10px;
                        width: $width-100 !important;
                    }
                }
            }
        }
    }
}
