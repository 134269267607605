@import '../../../../../../Css/variables';

// Modale
.modaleForm {
    background-color: $color-background;
    .modaleHeader {
        border: none;
        background-color: transparent;
    }

    .formBody {
        .formInput {
            background-color: $color-primary-back;
        }
    }

    .formFooter {
        border: none;
        .formCancel {
            float: left !important;
            @include button-secondary;
        }
        .formSubmit {
            @include button-primary;
        }
    }

    .modaleShare {
        border: none;
        background-color: transparent;
        .btn{
            background-color: $color-background !important;
            border: $border-2;
            &:hover{
                 background-color: $color-hover-bg !important;
            }
        }
    }
}