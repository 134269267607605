@import "../../../Css/variables";

.card {
    width: $width-100 !important;
    border: $border-2;
    margin-bottom: $margin-16;
    background-color: $color-primary-white;

    .cardHeader {
        display: inline-flex;
        background-color: transparent !important;
        border: none;
        .btnOptionPosts {
            position: absolute;
            right: 0px;
            top: 0px;
            color: $color-hr !important;
            padding: $padding-5 $padding-5;
            @include optionItems();
        }
    }
    .cardBodyText {
        .description {
            text-align: justify;
            padding: $padding-10;
            border-radius: $border-raduis-5;
        }
        .dateNotify {
            color: $color-font-secondary-f;
            float: right;
            font-size: $font-size-10;
        }
    }
}

// mobile and tablet 991
@media (max-width: $max-width-991) {
    .card {
        .cardHeader {
            svg {
                width: 15px;
                height: 15px;
            }
        }
    }
}
