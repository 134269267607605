@import "./variables";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $font-family;
    font-weight: $font-weight-normal !important;
    font-size: $font-size-16;
}

html {
    overflow-x: hidden;
    body {
        background-color: $color-background !important;
        color: $color-font-primary;
    }
}

*:focus {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d2d2d9;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bac8d9;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #bac8d9;
}

// selection
::-moz-selection {
    /* Code for Firefox */
    color: $color-primary-white;
    background: #31ceda;
}

::selection {
    color: $color-primary-white;
    background: #31ceda;
}
