@import "../../../Css/variables";

.btnAddNew {
  width: 100%;
  height: 50px;
  @include button-primary();
  p {
    float: $float-right;
  }

  .faIconAdd {
    display: inline;
    float: $float-left;
    font-size: 1.5em;
  }

  @media (max-width: 767px) {
    p {
      font-size: $font-size-10;
    }

    .faIconAdd {
      font-size: $font-size-10;
    }
  }
}

@media (max-width: 991px) {
  .btnAddNew {
    height: 39px;
  }
}
