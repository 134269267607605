@import "../../../../Css/variables";
.filCar {
  .btn-option-member {
    position: absolute;
    right: 0px;
    top: 0px;

    .items {
      padding: $padding-10;
      background-color: $color-primary-back !important;
      .item {
        cursor: pointer;
        &:hover {
          background-color: $color-primary-1;
          color: $color-primary-white;
        }
      }
    }
  }
  min-height: 80px !important;
  padding: $padding-10;
  background-color: #fff !important;
  border-radius: $border-raduis-10 !important;

  p {
    text-align: left !important;
    padding-right: 3px;
  }

  .iconFilterCard {
    color: #212529;
    font-weight: bold;
    position: absolute;
    right: 10px;
  }

  .date {
    text-align: right;
  }
}
