@import "../../Css/variables";

.formSelect {
  width: $width-50;
  margin-top: $margin-16;
  margin-bottom: $margin-20;
  padding: $padding-16 !important;
  color: $color-primary-4 !important;
  cursor: pointer;
  border: $border-2 !important;

  .option {
    background-color: $color-white !important;
    color: $color-font-secondary-f;
    border: none;
    cursor: pointer;
  }
}

@media (max-width: $max-width-991) {
  .formSelect {
    width: $width-100 !important;
    padding: $padding-10 !important;
  }
}
