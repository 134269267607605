@import "../../../../Css/variables";

.register {
    justify-content: center;
    @include flexCenter;
    .card {
        padding: $padding-16 !important;
        .cardHeader {
            background-color: transparent !important;
            border: none !important;
        }
        .hr {
            margin: 0;
            padding: 0;
        }
        .form {
            .lblName,
            .textArea {
                display: block;
                width: $width-100;
                background-color: $color-primary-back;
                color: $color-primary-4;
                outline: none;
                border: none;
                padding: $padding-5;
                border-radius: $border-raduis-5;
                margin-bottom: $margin-16;
            }
        }
    }

    .btnRegister {
        @include button-primary;
        float: right;
        margin: 0;
    }

    .btnCancel {
        @include button-secondary;
        float: left;
        margin: 0;
    }
}

@media (max-width: $max-width-991) {
    .register {
        .card {
            width: $width-90 !important;
        }
    }
}
