@import "../../Css/variables";

.setting {
    margin-top: $margin-20;
    width: $width-25 !important;

    .card {
        width: $width-50;
        height: max-content !important;
        background-color: $color-primary-white !important;
        padding: $padding-16 !important;
        border-radius: $border-raduis-10 !important;
        color: $color-primary-4 !important;
        transition: ease-in-out $transition-3;
        display: block;
        border: $border-2 !important;

        .hr {
            width: $width-96 !important;
            height: 1px !important;
            margin: $margin-16 $margin-16 $margin-16 $margin-16 !important;
        }

        .cardBody {
            padding: $padding-16 !important;

            .cardTitle {
                margin: $margin-16 $padding-0 $margin-20 $padding-0 !important;
                opacity: $opacity-8 !important;
                font-size: medium;
            }
            .username {
                clear: both;
                span {
                    float: right;
                }
            }
            .email {
                clear: both;
                span {
                    float: right;
                }
            }
            .dark {
                width: $width-100;
                clear: both;
                p {
                    float: left !important;
                }
                .btnSwitch {
                    float: right !important;
                }
            }
            .language {
                @include flexCenter;
                p {
                    width: $width-100;
                    margin: $margin-0 !important;
                }
                select {
                    float: right !important;
                    margin: $margin-0 !important;
                }
            }
            .edit {
                color: unset;
                text-decoration: none;
                float: right;
                @include button-primary();
                border-radius: $border-raduis-5;
            }
        }
    }
}

// mobile
@media (max-width: $max-width-991) {
    .setting {
        .card {
            transition: $transition-3;
            width: $width-90 !important;
            font-size: small !important;
            margin-bottom: 100px;
        }

        .cardBody {
            padding: $padding-0 !important;
        }
        .cardTitle {
            font-size: $font-size-8 !important;
        }
    }
}
