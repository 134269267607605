@import "../../Css/variables";

.mainPage {
    .tabs {
        margin-left: -0.7em !important;
        position: fixed;
        display: flex;
        left: $left-5;
        top: $top-3;
        z-index: 1;
        vertical-align: middle;
        @include flexCenter;
        transition: $transition-3;

        :hover {
            background-color: $color-white;
            color: $color-hover-bg !important;
            border-right: 1px solid $color-blood !important;
            border-bottom: 1px solid $color-blood !important;
            a {
                color: $color-hover-bg !important;
            }
        }
        &Item {
            padding: $padding-10;
            border-end-end-radius: $border-raduis-10 !important;
            background-color: $color-hover-bg;
            text-decoration: none;
            color: $color-white;
            font-size: $font-size-10 !important;

            a {
                text-decoration: none;
                color: $color-white !important;
            }
        }
    }
    .active {
        border-right: 1px solid $color-blood !important;
        border-bottom: 1px solid $color-blood !important;
        background-color: $color-white !important;
        color: $color-primary-1 !important;
    }
}

@media (max-width: $max-width-991) {
    .mainPage {
        .tabs {
            position: fixed;
            display: flex;
            left: 0.7em;
            top: 3.46em;
            z-index: 1;
            vertical-align: middle;
            @include flexCenter;
            transition: $transition-3;
            font-weight: $font-weight-bold;

            &Item {
                padding: $padding-5;
                width: max-content !important;
            }
        }
    }
}
