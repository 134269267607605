@import "../../../../Css/variables";

.div-footer {
  display: $display-flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  height: 40px;
  width: 100%;
  position: fixed;
  bottom:0 !important;
  color: black;
  // margin-top: 3em;
  .div-icons {
    display: $display-flex;
    justify-content: space-around;
    align-items: center;
    width: 450px;
    .icons {
      height: 15px;
      width: 20px;
    }
    label{
      font-size: 10pt;
    }
  }
  .div-copyright{
    display: $display-flex;
    justify-content: space-around;
    align-items: center;
    .logo-svg {
      height: 25px;
    }
    label{
      font-size: 10pt;
    }
  }
  .div-aboutUs {
    display: $display-flex;
    justify-content: space-around;
    width: 600px;
    label{
      font-size: 10pt;
    }
  }
}

@media (max-width: 991px) {
  .div-footer{
    display: none !important;
  }
}
