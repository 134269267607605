@import "../../Css/variables";

.info {
    text-align: justify;
    padding: $padding-16;

    .btn {
        @include button-primary();
        margin: $margin-16 $margin-0 $margin-16 $margin-0;
    }
    .ScrollUpButton__Container {
        background-color: #bac8d9 !important;
        border: $border-2;
        border-color: $color-hover-bg;
        margin-bottom: $margin-50;
    }
}
