@import "../../../../../../Css/variables";

.steps {
    .card {
        margin-top: $margin-20;
        background-color: $color-primary-white;
        padding: $padding-30;
        border: $border-2;

        // step one
        .qrCodeUserName {
            float: right;
            padding: $padding-0 !important;
            margin: auto !important;
            justify-content: center !important;
            text-align: center !important;
        }

        .stepIcon {
            float: right !important;
        }
    }

    .btnDownload {
        @include button-primary;
        float: right;
    }
}

@media (max-width: $max-width-991) {
    .cardBody {
        margin-bottom: $margin-20 !important;
    }
    .qrCodeUserName {
        width: $width-100 !important;
    }
}
