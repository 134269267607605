@import "../../../../../Css/variables";

.reports {
    margin-top: $margin-top-6 !important;
    justify-content: center;
    .line {
        display: flex !important;
        @include flexCenter;
        @include optionItems();
        .btnFilter {
            @include flexCenter;
            display: flex;
        }
        .lineHr {
            float: left !important;
            width: $width-100 !important;
            padding: 0px !important;
            margin: 0px !important;
        }
    }
    .card {
        width: $width-100;
        margin-top: $margin-16;
        .date {
            float: right !important;
            color: $color-font-secondary-f !important;
        }
        .btnOptionReport {
            float: right !important;
        }

        .cardBodyItems {
            margin: $margin-16;
            .hr {
                width: $width-100 !important;
                padding: 0px !important;
                margin: 0px !important;
            }
        }
    }
}

// mobile 991
@media (max-width: $max-width-991) {
    .reports {
        .line {
            display: flex !important;
            .line-hr {
                width: $width-100 !important;
                padding: 0px !important;
                margin: 0px !important;
            }
        }
        .card {
            width: $width-100 !important;
            margin-bottom: $margin-5;
            .btn-option-report {
                svg {
                    width: 15px;
                    height: 15px;
                }
            }
            .card-text {
                font-size: $font-size-10;
            }

            .date {
                font-size: $font-size-10;
            }
        }
    }
}
