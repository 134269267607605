@import "../../../../Css/variables";

.formLogin {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $color-primary-white;
  margin: auto;
  margin-top: 4em;
  margin-bottom: 3em;
  padding: 20px;
  height: 55% !important;
  width: 80%;
  border-radius: 15px;
  box-shadow: $box-shadow-1;
  .logo-dl {
    height: 450px;
  }
  .vertical-line {
    display: inline-block;
    border-left: 6px solid $color-primary-1;
    height: 450px;
    border-radius: 10px;
    margin-right: 10px !important;
  }
}

.form-input-login {
  width: 100%;
  // padding: 5px;
  margin-left: 10px;
  h2 {
    text-align: center;
  }
  .btn-forminput-login{
    @include button-primary
  }
  .div-footer-login {
    display: flex;
    .div-info-login {
      display: flex;
      margin-left: auto;
      .lbl-info-login {
        font-size: 10pt;
        margin-top: 15px;
        margin-right: $margin-10;
      }
      .btn-forminput-register{
        @include button-secondary;
      }
    }
  }
}

.navbar-login {
  background-color: $color-primary-1 !important;
  height: 60px;
  position: sticky !important;
  top: 0;
  z-index: 1;
  .App-logo-login {
    height: 6vmin;
    pointer-events: none;
    background-color: honeydew;
    border-radius: 10px;
    width: 55px;
    margin-right: $margin-10;
    margin-left: $margin-10;
    // height: 58px;
  }
  .nv-brand {
    color: $color-primary-2 !important;
    background-color: $color-primary-1;
  }
  .nav-form-login {
    display: $display-flex;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    #basic-nav-dropdown {
      color: $color-primary-3;
    }
    .btn-create-account {
      @include button-secondary;
      text-decoration-line: none;
      border-radius: $border-raduis-3;
      text-align: center;
      margin-right: 5px;
      width: 99%;
    }
  }
}
@media (max-width: $max-width-991) {
  .formLogin {
    margin-top: 19em;
    width: 90%;
    .logo-dl {
      display: none;
    }
    .vertical-line {
      display: none;
    }
  }
  .form-input-login{
    .div-footer-login{
      .div-info-login{
        display: block;
      }
    }
  }
  .navbar-login{
    .App-logo-login{
      height: 45px;
    }
    .collapse{
      background-color: $color-primary-1;
      .nav-form-login{
        display: flex;
        flex-direction: column;
      }
      .btn-create-account{
        margin-bottom: 5px;
        margin-left: 5px;
      }
    }
  }
}
