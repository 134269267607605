@import "../../../../../Css/variables";

.posts {
    justify-content: center;

    .form {
        .textArea {
            width: $width-100;
            outline: none;
            border: none;
            padding: $padding-10;
            border-radius: $border-raduis-5 !important;
            background-color: #fff !important;
            color: $color-primary-4 !important;
            border: $border-2;
            min-height: 100px;
        }
        .btnPrimary
        {
            @include button-primary;
            float: right;
            margin-bottom: $margin-16;
        }
    }
    .teamFixAppointmenet {
        clear: both;
        display: block !important;
        position: fixed;
        bottom: 50px;
        right: 0px;
        padding: $padding-16;
        .btnRight {
            @include button-primary;
            margin-bottom: $margin-5;
        }
    }
    .newPosts {
        margin-top: $margin-20;
        .card {
            width: $width-100 !important;
            .cardHeader {
                display: inline-flex;
                background-color: transparent !important;
                border: none;
                .btnOptionPosts {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    color: $color-hr !important;
                    padding: $padding-5 $padding-5;
                    @include optionItems();
                }
            }
            .cardBodyText {
                padding-bottom: $padding-10;
                .description {
                    border: $border-2;
                    padding: $padding-10;
                    border-radius: $border-raduis-5;
                }
                .btnLike {
                    color: $color-blood;
                }
                .likeCount {
                    color: $color-primary-4;
                    font-size: $font-size-10;
                }
            }
            .cardBodyComments {
                padding-top: $padding-0 !important ;
                .comments {
                    background-color: $color-white;
                    border-radius: $border-raduis-5;
                    padding: $padding-5;
                    font-size: $font-size-8;
                    margin: $margin-5 $margin-0 $margin-5 $margin-10;
                    color: $color-primary-4;
                    border: $border-2;
                    .commentsOption {
                        display: flex;
                        @include optionItems;
                        .notifyIcon {
                            color: $color-hr !important;
                            position: absolute;
                            right: 25px;
                        }
                    }
                    .userCommentText {
                        margin-left: $margin-16;
                    }
                }
                .postComments {
                    padding: $padding-10 $padding-0 $padding-0 $padding-10;
                    z-index: 0;
                    .inputGroupText {
                        @include button-primary;
                    }
                    .formControl {
                        color: $color-hr !important;
                        background-color: $color-primary-back !important;
                    }
                }
            }
        }
    }

    .readOrHide {
        color: $color-hr;
        cursor: pointer;
        svg {
            padding: $padding-0;
            width: 35px;
            height: 35px;
        }
    }
}

// mobile and tablet 991
@media (max-width: $max-width-991) {
    .posts {
        .teamFixAppointmenet {
            bottom: 40px !important;
        }
        .newPosts {
            .card {
                .cardHeader {
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
                .cardBodyComments {
                    .comments {
                        background-color: $color-primary-3;
                        border-radius: $border-raduis-10;
                        padding: $padding-5;
                        font-size: $font-size-10;
                        margin: $margin-5 $margin-0 $margin-5 $margin-10;
                        .commentsOption {
                            .option {
                                right: 15px;
                                svg {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }
                    }

                    .readOrHide {
                        font-size: $font-size-10 !important;
                        svg {
                            padding: $padding-0;
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .seeMore {
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
}
