@import url("https://fonts.googleapis.com/css2?family=Merienda+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700&display=swap");
// colors

$color-primary-back: #eaf6f6;
$color-background: #e4e4e4;
$color-primary-white: #ffffff;
$color-primary-1: #32aeb8;
$color-primary-2: #f4f4f4;
$color-primary-3: #f4f4f4;
$color-primary-4: #8f8f8f;
$color-primary-5: #5f5f5f;
$color-primary-6: #8f8f8f;
$color-primary-7: #039fbe;
$color-blood: #ae051a;
$color-gold: #ffaa00;
$color-font-primary: #8f8f8f;
$color-font-secondary-f: $color-primary-4;
$color-font-secondary-e: #dedede;
$color-font-grey: #ffffffa2;
$color-white: #f4f4f4;
$color-hover-bg: #31ceda;
$color-hr: #bac8d9;
// fonts
$font-family: "Cairo", sans-serif /*Arial, sans-serif*/ /*"Merienda One", cursive*/;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-normal: 400;

// font size
$font-size-5: 5pt;
$font-size-8: 8pt;
$font-size-10: 10pt;
$font-size-16: 16pt;
$font-size-20: 20pt;
$font-size-22: 22pt;
$font-size-25: 25pt;

// border raduis
$border-raduis-3: 3px;
$border-raduis-5: 5px;
$border-raduis-10: 10px;
$border-raduis-16: 16px;

// border
$border-1: 1px solid $color-white;
$border-2: 1px solid #bac8d9;

// paddings
$padding-0: 0;
$padding-5: 5px;
$padding-10: 10px;
$padding-16: 16px;
$padding-20: 20px;
$padding-30: 30px;
$padding-50: 50px;

// margins
$margin-0: 0;
$margin-3: 3px;
$margin-5: 5px;
$margin-8-perce: 8%;
$margin-10: 10px;
$margin-16: 16px;
$margin-20: 20px;
$margin-30: 30px;
$margin-50: 50px;
$margin-70: 70px;
$margin-80: 80px;

// margin-top
$margin-top-6: 6em;

// width
$width-25: 25%;
$width-50: 50%;
$width-70: 70%;
$width-90: 90%;
$width-96: 96%;
$width-100: 100%;
$width-80px: 80px;
$width-max: max-content;
$width-min: min-content;

// max width
$max-width-991: 991px;

// top
$top-3: 3.35em;

// left
$left-5: 4.3em;

//opacity
$opacity-2: 0.2;
$opacity-3: 0.3;
$opacity-7: 0.7;
$opacity-8: 0.8;
$opacity-9: 0.9;
$opacity-1: 1;

//side column width:
$side-col-width: 78px;

// height
$height-50: 50px;
$height-100: 100%;

//nav height
$nav-height: 60px;
$height-navbar-top: 45px;

//line height
$line-height-50: 50px;

//float
$float-left: left;
$float-right: right;

// display
$display-flex: flex;

// transition
$transition-3: 0.3s;
$transition-2: 0.2s;

// box shadow
$box-shadow-1: 0 5px 5px 0 rgba(0, 0, 0, 0.01), 0 5px 10px 0 rgba(0, 0, 0, 0.1) !global;

//mixin center
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin button-primary {
  background-color: $color-primary-1;
  padding: $padding-10;
  color: #fff;
  font-weight: $font-weight-semi-bold !important;
  border: $border-2;

  &:hover {
    box-shadow: $box-shadow-1;
    background-color: #fff !important;
    color: $color-primary-1 !important;
    border: $border-2;
  }
}
@mixin button-secondary {
  padding: $padding-10;
  background-color: $color-primary-2;
  color: $color-font-secondary-f;
  border: $border-2;

  &:hover {
    background-color: #fff !important;
    color: $color-primary-1 !important;
    box-shadow: $box-shadow-1;
  }
}

@mixin cardStyle {
  background-color: #fff !important;
  box-shadow: $box-shadow-1;
  color: #212529 !important;
  padding: $padding-16 !important;
  outline: none;
  border-radius: $border-raduis-10 !important;
}

@mixin btnCustom {
  background-color: $color-primary-1 !important;
  border: 2px solid $color-primary-1 !important ;
  color: #fff !important;
  &:hover {
    background-color: #3fd7e2 !important;
    border: 2px solid #3fd7e2 !important ;
  }
}

@mixin margin-top-bottom-80 {
  margin-top: $margin-20 !important;
  margin-bottom: $margin-80 !important;
}

// this mixin for option dialog
@mixin optionItems {
  .items {
    padding: $padding-5;
    background-color: $color-white !important;
    .item {
      cursor: pointer;
      padding: $padding-5;
      border-radius: $border-raduis-3;
      color: $color-primary-4;
      &:hover {
        background-color: $color-hover-bg !important;
        color: $color-primary-white;
      }
    }
  }
}
