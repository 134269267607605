@import "../../Css/variables";

.dateTime {
  margin-top: 10%;
  width: $width-70;
  margin-left: 20%;

  .btn {
    margin-top: $margin-20;
    @include button-primary;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 50px;
    float: right;
    margin-top: 50px;
    text-decoration: none;
    border: none;
    border-radius: $border-raduis-10;
    box-shadow: $box-shadow-1;
  }

  .cancel {
    @include button-secondary;
    float: left !important;
    margin-left: $margin-0 !important;
    background-color: $color-primary-6;
    color: $color-white;
    text-decoration: none;
    border: none;
    border-radius: $border-raduis-10;
    box-shadow: $box-shadow-1;
  }
}

.calendarCard {
  border-radius: $border-raduis-10 !important;
  padding: $padding-16;
  background-color: $color-primary-white !important;
  box-shadow: $box-shadow-1;

  .title {
    color: $color-primary-1;
  }
}

.timeCard {
  margin-top: $margin-30;
  border-radius: $border-raduis-10;
  padding: $padding-16;
  background-color: $color-primary-white !important;
  box-shadow: $box-shadow-1;
  .day:hover {
    background-color: $color-primary-1 !important;
  }

  .title {
    color: $color-primary-1;
  }
}

// step date time
.timeRow {
  width: $width-100;
  font-size: 10pt;
  color: $color-font-secondary-f !important;

  .timeCol {
    padding: $padding-10;
    border: $border-1;
    margin: $margin-3;
    border-radius: $border-raduis-10;
  }

  .timeCol:active {
    background-color: $color-primary-1;
  }
}
// mobile
@media (max-width: $max-width-991) {
  .dateTime {
    width: $width-100;
  }
}
