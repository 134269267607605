@import "../../../../Css/variables";

.donationSteps {
    .btnAppointment {
        float: right;
        color: #8f8f8f;
        padding: $padding-0 !important;
        .iconAppointment
        {
            margin-left: $margin-5;
        }
    }

    .stepsTop {
        width: $width-100;
        margin-top: $margin-30;
        margin-bottom: $margin-50;

        .instructions {
            margin-top: $padding-16;
            margin-bottom: $padding-16;
        }
        .stepper {
            padding: $padding-16;
            border-radius: $border-raduis-5;
            border: $border-2;
        }
        .btnNext {
            @include button-primary;
            margin-left: $margin-10;
            box-shadow: none;
        }

        .btnReset {
            @include button-primary;
            margin-left: $margin-0;
            box-shadow: none;
        }

        .btnBack {
            @include button-secondary;
            box-shadow: none;
        }
    }
}
