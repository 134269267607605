@import "../../Css/variables";

// Donor NavBar Style
.donorHeader {
    .navBar {
        top: 0;
        width: $width-100;
        max-width: $width-100 !important;
        background-color: $color-primary-1 !important;
        padding: $padding-0 !important;
        z-index: 2;

        &Brand {
            background-color: $color-white;
            border-radius: $border-raduis-5;
            padding: $padding-0 !important;
            margin: $margin-0 !important;
        }

        &Logo {
            width: 40px;
            height: 40px;
            transition: $transition-3;
        }

        .navBarHr {
            margin-right: $margin-10;
            margin-left: $margin-10;
            height: 10px;
            border: 1px solid $color-primary-1 !important;
            background-color: $color-white !important;
        }

        .navIconMenu {
            color: $color-white;
            font-size: $font-size-16 !important;
        }
    }

    // navbar nav for icons and drop downs
    .navbarNav {
        padding: $padding-0 !important;
        .navItem {
            margin: $margin-10;

            :hover {
                background-color: $color-white;
                color: $color-primary-1 !important;
                border-radius: $border-raduis-5;

                // tooltip message visible true
                .tooltiptext {
                    visibility: visible;
                }
            }
        }

        .navLink {
            color: $color-white !important;

            .badge {
                width: 15px;
                height: 15px;
                font-size: 6pt;
            }
        }
    }

    // avatar with badge
    .navProfile {
        &Badge {
            color: $color-gold !important;
            background-color: $color-primary-1 !important;
            border-radius: $border-raduis-3;
            &:hover {
                color: $color-white !important;
            }
        }
        .avatar {
            border-radius: $border-raduis-5;
        }
    }

    // drop down language and username
    .dropdownMenu {
        background-color: $color-primary-3 !important;

        .dropdownItem {
            color: $color-font-primary !important;

            &:hover {
                background-color: $color-hover-bg !important;
                color: $color-primary-2 !important;
            }
        }
    }
}

@media (max-width: $max-width-991) {
    .donorHeader {
        .navBar {
            transition: $transition-3;

            &Logo {
                width: 25px;
                height: 25px;
                transition: ease-in-out $transition-3;
            }
            .navBarHr {
                height: 20px !important;
            }
        }
    }
}
