@import "../../../../../../Css/variables";

.steps {
    .card {
        margin-top: $margin-20;
        background-color: $color-primary-white;
        padding: $padding-30;
        border: $border-2;

        // step two
        .scanner {
            float: right;
            padding: $padding-0 !important;
            margin: auto !important;
            justify-content: center !important;
            text-align: center !important;

            video {
                width: 150% !important;
                height: 150% !important;
                border: $border-2 !important;
                padding: $padding-10;
                border-radius: $border-raduis-5;
            }
            .btnScan {
                @include button-primary;
            }
        }

        .stepIcon {
            float: right !important;
        }
    }

    .btnDownload {
        @include button-primary;
        float: right;
    }
}

@media (max-width: $max-width-991) {
    .cardBody {
        margin-bottom: $margin-20 !important;
    }

    .scanner {
        @include flexCenter;
        width: $width-50 !important;
        video {
            width: 20% !important;
            height: 20% !important;
        }
    }
}
