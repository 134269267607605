@import "../../Css/variables";

.searchInput {
  background-color: $color-primary-white;
  margin: 0px 1px 40px auto;
  width: 30% !important;
  color: $color-primary-1;
  display: inline !important;
  box-shadow: $box-shadow-1;
  &:focus {
    background-color: $color-primary-6;
  }
  &:-webkit-input-placeholder {
    color: black;
  }
}

.searchIcon {
  background-color: $color-primary-1;
  color: white;
  border-radius: 30%;
  margin-left: 6px;
  &:hover {
    background-color: $color-primary-4;
  }
}

@media (max-width: 320px) {
  .searchInput {
    margin: 0px 1px 40px auto;
    width: 30% !important;
  }

  .searchIcon {
    margin-left: 6px;
  }
}
@media (max-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .searchInput {
    margin: 100px auto 0px auto;
    width: 40% !important;
    background-color: black;
  }

  .searchIcon {
    margin-left: 6px;
  }
}
@media (max-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .search-input {
    width: 150px;
    margin: 100px 1px 40px 60px;
  }
  .search-icon {
    margin: 0px;
  }
}
@media (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .searchInput {
    margin: 100px auto 30px auto;
    width: 120px !important;
  }

  .searchIcon {
    margin-left: 6px;
  }
}

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .searchInput {
    margin: 0px 1px 40px auto;
    width: 30% !important;
  }

  .searchIcon {
    margin-left: 6px;
  }
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .searchInput {
    margin: 0px 1px 40px auto;
    width: 30% !important;
  }

  .searchIcon {
    margin-left: 6px;
  }
}
