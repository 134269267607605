@import "../../../Css/variables";

// navbar nav for icons and drop downs
.navItemNotify {
    margin: $margin-10;
    list-style: none;
    position: absolute;
    right: 20em;
    .navLinkNotify {
        color: $color-white !important;

        .badgeNotify {
            width: 21px;
            height: 21px;
            font-size: 8pt;
        }
    }
    .dropdownMenuNotify {
        background-color: $color-white !important;
        width: 16em;
        border: $border-2 !important;

        .filter {
            color: $color-hr;
            padding: $padding-0 $padding-10 $padding-5 $padding-10;

            .iconFilter{
                cursor: pointer;
            }

            .iconEllipseH{
                float: right;
                cursor: pointer;
            }
        }

        .notifyPadding {
            padding: $padding-10;
            height: 80vh;
            overflow-y: auto;
            direction: rtl;

            .notif {
                background-color: $color-primary-white !important;
                margin-bottom: $margin-5 !important;
                border-radius: $border-raduis-5;
                padding: $padding-5 !important;
                border: $border-2;
                color: $color-font-secondary-f !important;
                cursor: pointer;
                font-size: $font-size-10;
                margin-bottom: $margin-16 !important;

                .linkNotify {
                    color: $color-font-secondary-f;
                    text-decoration: none;
                    .dateNotify {
                        color: $color-font-secondary-f;
                        float: right;
                        margin-top: -$margin-20 !important;
                        font-size: $font-size-8;
                    }
                }
            }
        }
    }
}

@media (max-width: $max-width-991) {
    .navItemNotify {
        top: 0.1em;
        right: 5em;
        .navLinkNotify {
            color: $color-white !important;

            .badgeNotify {
                width: 15px;
                height: 15px;
                font-size: 8pt;
            }
        }
        .dropdownMenuNotify {
            position: absolute !important;
            left: -11em !important;
            width: 20em;
            background-color: $color-primary-3 !important;
            .notifyPadding {
                padding: 5px;
            }
        }
    }
}
