@import "../../../Css/variables";
@import "./Succes/Succes.module.scss";

.appointment {
  @include margin-top-bottom-80;
  @include flexCenter;
  top: 50px !important;

  // generale card design
  .card {
    width: $width-50;
    height: max-content !important;
    background-color: $color-primary-white !important;
    padding: $padding-16 !important;
    border-radius: $border-raduis-10 !important;
    color: $color-primary-4 !important;
    transition: ease-in-out $transition-3;
    border: none !important;
    border: $border-2 !important;

    .cardHeader {
      opacity: $opacity-8;
      background-color: transparent !important;
      border: none !important;
    }

    .hr {
      width: $width-96 !important;
      height: 1px !important;
      margin: $margin-5 $margin-16 $margin-5 $margin-16 !important;
    }

    .cardBody {
      padding: $padding-16 !important;

      .cardTitle {
        margin: $margin-16 $padding-0 $margin-20 $padding-0 !important;
        opacity: $opacity-8 !important;
        font-size: medium;
      }

      .btn {
        @include button-primary;
        float: right;
        margin-left: $margin-10;
        margin-top: $margin-10;
      }

      .previous {
        @include button-secondary;
        left: 0;
        margin-left: $margin-0;
        float: left;
      }
    }
  }
}

// mobile
@media (max-width: $max-width-991) {
  .appointment {
    .card {
      transition: $transition-3;
      width: $width-90 !important;
      font-size: small !important;
      margin-bottom: 100px;

      .formSelect {
        width: $width-100 !important;
      }
    }

    .cardBody {
      padding: $padding-0 !important;
    }

    .cardHeader {
      font-size: $font-size-10 !important;
      margin-left: 0 !important;
      padding-left: 0 !important;
    }

    .cardTitle {
      font-size: $font-size-8 !important;
    }

    .btn {
      left: 0 !important;
      right: 0 !important;
      font-size: $font-size-10 !important;
    }

    .previous {
      float: left !important;
      font-size: $font-size-10 !important;
      margin-left: $margin-0 !important;
    }
  }
}
