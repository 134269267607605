@import "../../../../Css/variables";

.formRegister {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85% !important;
  margin-top: 4em !important ;
  margin: auto;
  margin-bottom: 4em ;
  box-shadow: $box-shadow-1;
  border-radius: 15px;
  background-color: $color-primary-white;
  .logo-dl {
    height: 500px;
    margin: 100px -20px;
  }
  .vertical-line { 
    display: inline-block;
    border-left: 6px solid $color-primary-1;
    height: 500px;
    border-radius: 10px;
  }
  .form-register {
    width: 50% !important;
    margin: auto;
    h2 {
      text-align: center;
    }
    .MuiFormGroup-root {
      display: inline !important;
    }

    .form-Control {
      margin-bottom: 10px;
    }
    .group-radio {
      //display: flex;
      .lbl-radio {
        margin-right: 60px;
      }
      .radio-man {
        margin-right: 10px;
      }
    }
    .btn-create-register2 {
      @include button-primary;
    }
    .div-footer-register2 {
      display: flex;
      margin-top: 0px;
      .lbl-info-register2 {
        font-size: 10pt;
        margin-top: 15px;
      }
      .div-info-register2 {
        display: flex;
        margin-left: auto;
        .lbl-info2-register2 {
          font-size: 10pt;
          margin-top: 15px;
          margin-right: 5px;
        }
        .btn-login-register2 {
          @include button-secondary;
        }
      }
    }
  }
}

.formRegister2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85%;
  // height: 30em;
  margin: auto;
  margin-top: 4em;
  margin-bottom: 4em;
  padding: 10px;
  border-radius: 15px;
  background-color: $color-primary-white;
  box-shadow: $box-shadow-1;
  .form-register2 {
    width: 50%;
    padding-right: 10px;
    padding-left: 15px;
    h2 {
      text-align: center;
    }
    .selectionCountry {
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
    }
    .form-group-register2 {
      margin-bottom: 10px;
      .form-control-register2 {
        height: 44px;
      }
      .selectionState {
        width: 100%;
        height: 40px;
      }
      .selectionCity {
        width: 100%;
        height: 40px;
      }
      .selection-number {
        width: 105px; 
        height: 42px;
      }
      .form-controle-tel{
        width: 100%; 
        height: 42px;

      }
      .form-control-register2-adress {
        height: 44px;
      }
      .btn-send {
        @include button-secondary;
        width: 100%;
        height: 42px !important;
      }
      .lbl-confirm {
        font-size: 14pt;
      }
    }
    .btn-create {
      @include button-primary;
      width: 20%;
    }
    .div-info-register2 {
      display: flex;
      margin-left: auto;
      .lbl-info {
        font-size: 10pt;
        margin-top: 10px;
      }
      .lbl-info-center {
        margin-left: auto;
        font-size: 10pt;
        margin-top: $margin-10;
      }
    }
  }
  .logo-dl-register2 {
    height: 500px;
    margin: 120px -40px;
  }
  .vertical-line-register2 {
    display: inline-block;
    border-left: 6px solid $color-primary-1;
    height: 500px;
    border-radius: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 991px) {
  .formRegister {
    width: 75% !important;
    height: 45em;
    margin-top: 20em;
    padding: 10px;
    .logo-dl {
      display: none;
    }
    .vertical-line {
      display: none;
    }
    .form-register {
      width: 90% !important;
      .Row-Control{
        gap:10px;
        .Form-Row{
          padding: 0;
        }
      }

      .colControlLast {
        margin-right: -10px;
      }
      .colControlPass1 {
        margin-left: -10px;
      }
      .colControlPass2 {
        margin-right: -10px;
      }
      .div-footer-register2 {
        .div-info-register2 {
          display: flex;
          flex-direction: column;
          .lbl-info2-register2 {
            margin-left: 25px;
          }
          .btn-login-register2 {
            margin-left: 13em;
          }
        }
      }
    }
  }

  .formRegister2 {
    width: 75%;
    height: 45em;
    margin-top: 10em;
    padding: 10px;
    .form-register2 {
      width: 100%;
      h2 {
        text-align: center;
        margin-bottom: 30px;
      }
      .selectionCountry {
        width: 97%;
        margin-left: 10px;
      }
      .form-group-register2 {
        .form-control-register2 {
          height: 44px;
        }
        .selectionState {
          margin-bottom: 10px;
        }
        .selectionCity {
          margin-bottom: 10px;
        }
        .selection-number {
          margin-bottom: 10px;
        }
        .form-control-register2-adress {
          width: 97%;
          margin-left: 10px;
        }
        .btn-send {
          height: 43px !important;
        }
        .lbl-confirm {
          font-size: 10pt;
          margin-top: 5px;
        }
      }
      .btn-create {
        margin-left: 10px !important;
      }
      .div-info-register2 {
        .lbl-info {
          margin-left: 10px !important;
        }
        .lbl-info-center {
          margin-right: 15px !important;
        }
      }
    }
    .logo-dl-register2 {
      display: none;
    }
    .vertical-line-register2 {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .formRegister {
    width: 90% !important;
    height: 45em;
    margin-top: 8em !important;
    // padding: 15px;
    .logo-dl {
      display: none;
    }
    .vertical-line {
      display: none;
    }
    .form-register {
      width: 90% !important;
      .colControlfirst {
        margin-left: -10px !important;
      }
      .colControlLast {
        margin-right: -10px;
      }
      .colControlPass1 {
        margin-left: -10px;
      }
      .colControlPass2 {
        margin-right: -10px;
      }
      .div-footer-register2 {
        .div-info-register2 {
          display: flex;
          flex-direction: column;
          .lbl-info2-register2 {
            margin-left: 25px;
          }
          .btn-login-register2 {
            margin-left: 13em;
          }
        }
      }
    }
  }

  .formRegister2 {
    width: 90%;
    height: 60em;
    margin-top: 6em;
    padding: 3px;
    margin-bottom: 20px;
    .form-register2 {
      padding-right: 0px;
      padding-left: 0px;
      h2 {
        margin-bottom: 20px;
      }
      .selectionCountry {
        width: 94%;
      }
      .form-group-register2 {
        .selectionState {
          margin-bottom: 10px;
        }
        .selectionCity {
          margin-bottom: 10px;
        }
        .selection-number {
          width: 100%;
          margin-bottom: 10px;
        }
        .form-control-register2-adress {
          width: 94%;
          margin-left: 10px;
        }
        .btn-send {
          margin-top: 10px;
          height: 43px !important;
          width: 100%;
        }
        .lbl-confirm {
          margin-top: 0px;
        }
      }
      .btn-create {
        width: 94%;
        margin-top: 10px;
      }
      .div-info-register2 {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        .lbl-info-center {
          margin-left: 10px;
        }
      }
    }
    .logo-dl-register2 {
      display: none;
    }
    .vertical-line-register2 {
      display: none;
    }
  }
}
