@import "../../Css/variables";
.pageNfound {
  .imageContainer {
    width: 60%;
    height: 60%;
    margin: $margin-0 auto;

    img {
      width: $width-100;
      height: $width-100;
    }
  }

  .title {
    color: $color-primary-1 !important;
    font-weight: bolder;
    font-size: 2em;
    margin: 0 auto;
    display: block;
    text-align: center;
    .txt {
      display: inline;
      font-size: 0.5em;
      color: #212529;
    }
  }

  .redirect {
    display: block;
    text-align: center;
    text-decoration: none;
  }
}
