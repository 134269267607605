@import "../../Css/variables";

.navMenuBar {
    padding-top: $padding-0;
    background-color: $color-primary-1;
    height: calc(100% - 70px);
    position: fixed;
    width: 3.6em;
    bottom: 0;
    z-index: 1;
    text-align: center;
    transition: $transition-3;

    .menuItemsIconText {
        width: $width-100;
        margin: $padding-0 $padding-0 $padding-5 $padding-0 !important;
        position: relative;
        display: block;
        text-decoration: none;
        color: $color-white;

        .sideIcon {
            svg {
                width: 20px !important;
                height: 20px !important;
            }
        }

        span {
            padding: $padding-0 $padding-5 $padding-0 $padding-5;
            font-size: $font-size-8;
            color: $color-font-grey;
        }

        &:hover {
            color: $color-white;
            background-color: $color-hover-bg !important;
            span {
                color: $color-white;
            }
        }
    }

    .active {
        background-color: $color-hover-bg !important;
        border-left: 5px solid $color-white !important;
        svg {
            color: $color-white;
        }
        span {
            color: $color-white !important;
        }
    }
}

.menuItemsIconsBottom {
    position: absolute;
    bottom: 0;
    width: $width-100;
    height: max-content;
    padding-bottom: $padding-16 !important;
    padding-top: $padding-16 !important;

    .menuItemsIconText {
        width: $width-100;
        margin: $padding-16 $padding-0 $padding-16 $padding-0 !important;
        position: relative;
        display: block;
        text-decoration: none;
        color: $color-white;

        .menuItemsIconText:hover {
            color: $color-white;
            background-color: $color-primary-2;
        }
    }
}

@media (max-width: $max-width-991) {
    .navMenuBar {
        background-color: transparent !important;
        height: 50px;
        position: fixed;
        width: $width-100;
        display: flex;
        bottom: 0;
        z-index: 1;
        text-align: center;
        transition: $transition-3;

        .menuItemsIconText {
            padding-top: $padding-5 !important;
            padding-bottom: $padding-5 !important;
            margin: $margin-0 !important;
            position: static;
            display: inline-block;
            background-color: $color-primary-1 !important;
            text-decoration: none;
            span {
                font-size: $font-size-5 !important;
            }
            svg {
                width: 20px !important;
                margin-bottom: -10px;
            }
        }
        .active {
            background-color: $color-hover-bg !important;
            border-left: none !important;
            border-top: 2px solid $color-blood !important;
            svg {
                color: $color-white;
            }
            span {
                color: $color-white !important;
            }
        }
    }

    .menuItemsIconsBottom {
        display: none;
    }
}
