@import "../../../Css/variables";

// search bar
.dFlex {
    background-color: $color-white !important;
    border-radius: $border-raduis-5;
    padding: $padding-0 !important;
    margin: $margin-0 !important;

    .formControl {
        color: $color-primary-1 !important;
        background-color: $color-white !important;
        font-size: $font-size-8;
        width: 300px;
    }
}

@media (max-width: $max-width-991) {
    .dFlex {
        position: absolute;
        top: 0.5em;
        left: 3em !important;
        background-color: red !important;
        .formControl {
            color: $color-primary-1 !important;
            background-color: $color-white !important;
            font-size: $font-size-8;
            width: 100%;
        }
    }
}
