@import "../../../Css/variables";

// NavBar Style
.navbar {
  background-color: $color-primary-1 !important;
  padding: $padding-0 !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 9999;

  &-brand {
    background-color: #fff;
    border-radius: $border-raduis-5;
    padding: $padding-0 !important;
    margin: $margin-0 !important;
  }

  &-logoPa {
    border-right: 1px solid #fff;
    width: 65px;
    &-logo {
      width: 3em;
      background-color: #fff;
      border-radius: $border-raduis-5;
    }
  }

  .nav-icon-menu {
    color: #fff;
    font-size: $font-size-16 !important;
  }
}

// search bar
.d-flex {
  background-color: #fff !important;
  border-color: #fff !important;
  border-radius: $border-raduis-5;
  margin-bottom: $margin-10;
  margin-top: $margin-10;
  .form-control {
    color: $color-primary-1 !important;
    background-color: #fff !important;
  }
}

// navbar nav for icons and drop downs
.navbar-nav {
  .nav-item {
    margin-left: $margin-10;
    margin-right: $margin-10;
    margin-top: $margin-10;
    margin-bottom: $margin-10;
    :hover {
      background-color: $color-primary-2;
      border-radius: $border-raduis-5;
    }
  }
  .nav-link {
    color: #fff !important;
    .badge {
      width: 15px;
      height: 15px;
      font-size: 6pt;
    }
  }
}

// avatar with badge
.nav-profile {
  &-badge {
    color: $color-gold !important;
    background-color: $color-primary-1 !important;
    border-radius: $border-raduis-3;
  }
  .avatar {
    border-radius: $border-raduis-5;
  }
}

// drop down language and username
.dropdown-menu {
  background-color: $color-primary-1 !important;

  .dropdown-item {
    color: $color-white !important;
    &:hover {
      background-color: #fff !important;
      color: $color-primary-2 !important;
    }
  }
}
