@import "../../Css/variables";

.share {
    margin-top: $margin-20;
    width: $width-25 !important;

    .card {
        width: $width-50;
        height: max-content !important;
        background-color: $color-primary-white !important;
        padding: $padding-16 !important;
        border-radius: $border-raduis-10 !important;
        color: $color-primary-4 !important;
        transition: ease-in-out $transition-3;
        border: $border-2 !important;

        .cardHeader {
            opacity: $opacity-8;
            background-color: transparent !important;
            border: none !important;
        }

        .hr {
            width: $width-96 !important;
            height: 1px !important;
            margin: $margin-16 $margin-16 $margin-16 $margin-16 !important;
        }

        .cardBody {
            padding: $padding-16 !important;

            .cardTitle {
                margin: $margin-16 $padding-0 $margin-20 $padding-0 !important;
                opacity: $opacity-8 !important;
                font-size: medium;
            }

            .formBody {
                .input {
                    border: $border-2;
                }
            }

            .btn {
                @include button-primary;
                float: right;
                margin-top: $margin-30;
            }
        }

        .modaleShare {
            border: none;
            background-color: transparent;
            .btn {
                background-color: $color-background !important;
                border: $border-2;
                &:hover {
                    background-color: $color-hover-bg !important;
                }
            }
        }
    }
}

// mobile
@media (max-width: $max-width-991) {
    .share {
        .card {
            transition: $transition-3;
            width: $width-90 !important;
            font-size: small !important;
            margin-bottom: 100px;
        }

        .cardBody {
            padding: $padding-0 !important;
        }

        .cardHeader {
            font-size: $font-size-10 !important;
            margin-left: 0 !important;
            padding-left: 0 !important;
        }

        .cardTitle {
            font-size: $font-size-8 !important;
        }

        .btn {
            left: 0 !important;
            right: 0 !important;
            font-size: $font-size-10 !important;
        }
    }
}
