@import "../../../../Css/variables";

.dateTime {
    margin-top: $margin-20;
    width: $width-70;
    .btnPrimary {
        margin-top: $margin-30;
        @include button-primary;
        left: 0 !important;
        right: 0 !important;
        float: right;
    }

    .previous {
        @include button-secondary;
        float: left !important;
        margin-left: $margin-0 !important;
    }
}

.calendarCard {
    border-radius: $border-raduis-10;
    padding: $padding-16;
    background-color: $color-primary-white !important;
    border: $border-2;
    .title {
        color: $color-primary-4;
    }
}

.timeCard {
    margin-top: $margin-16;
    border-radius: $border-raduis-10;
    padding: $padding-16;
    background-color: $color-primary-white !important;
    border: $border-2;
    .title {
        color: $color-primary-4;
    }

    // step date time
    .timeRow {
        width: $width-100;
        font-size: 10pt;
        color: $color-primary-4;
        background-color: transparent !important;

        .timeCol {
            padding: $padding-10;
            border: $border-1;
            margin: $margin-3;
            border-radius: $border-raduis-5;
        }

        .timeCol:active {
            background-color: $color-blood;
        }
    }
}

// mobile
@media (max-width: $max-width-991) {
    .dateTime {
        width: $width-100;
    }
}
