@import "../../../Css/variables";

.card {
    width: $width-100;
    border: $border-2;
    .avatar {
        .navItem {
            list-style: none !important;
        }

        .memberUsername {
            margin: $margin-16 $margin-16 $margin-0 $margin-16 !important;
            img {
                border-radius: $border-raduis-10;
                width: $width-100 !important;
            }
        }
    }
    .memberInfo {
        .btnOptionMember {
            position: absolute;
            right: 0px;
            top: 0px;
            padding: $padding-5 $padding-5;
            @include optionItems();

            .iconH {
                display: none;
            }

            .iconV {
                color: $color-hr;
            }
        }
        .cardBodyItems {
            margin-bottom: $margin-10 !important;
            .hr {
                margin: $margin-0 $margin-0 $margin-20 $margin-0 !important;
                padding: 0px !important;
                border: $border-2;
            }
        }
    }

    .hr {
        margin: $margin-0 $margin-0 $margin-20 $margin-0 !important;
        padding: 0px !important;
        border: $border-2;
    }
}

// mobile 991
@media (max-width: $max-width-991) {
    .card {
        width: $width-100 !important;

        .memberInfo {
            .btnOptionMember {
                margin-right: 5px;
                .iconV {
                    display: none;
                }
                .iconH {
                    display: unset;
                    color: $color-hr;
                }
            }
            .nextInformations {
                padding-left: 5px !important;
            }
        }
        .avatar {
            .memberUsername {
                img {
                    margin-top: 10px;
                    width: $width-100 !important;
                }
            }
        }
    }
}
