@import "../../../Css/variables";

.main {
  .team-tabs {
    margin-left: -17px !important;
    position: fixed;
    display: flex;
    left: $left-5;
    top: $top-3;
    z-index: 1;
    vertical-align: middle;
    @include flexCenter;
    transition: $transition-3;
    :hover {
      background-color: $color-white;
      color: $color-hover-bg !important;
      border-right: 1px solid $color-blood !important;
      border-bottom: 1px solid $color-blood !important;
      a {
        color: $color-hover-bg !important;
      }
    }
    &-item {
      padding: $padding-10;
      border-end-end-radius: $border-raduis-10 !important;
      background-color: $color-hover-bg;
      text-decoration: none;
      color: $color-white;
      font-size: $font-size-10 !important;
      a {
        text-decoration: none;
        color: $color-white !important;
      }
    }
  }
  .active {
    border-right: 1px solid $color-blood !important;
    border-bottom: 1px solid $color-blood !important;
    background-color: $color-white !important;
    color: $color-primary-1 !important;
  }

  @media (max-width: $max-width-991) {
    .team-tabs {
      position: fixed;
      display: flex;
      left: 1em;
      top: 36px;
      z-index: 1;
      vertical-align: middle;
      @include flexCenter;
      transition: $transition-3;
      font-weight: $font-weight-bold;

      &-item {
        padding: $padding-5;
        width: $width-100 !important;
      }
    }
  }

  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-width: 767px) {
    .container {
      padding-left: 20px !important;
      padding-right: 20px !important;
      width: 100% !important;
      max-width: none !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .container {
      padding-left: 40px !important;
      padding-right: 40px !important;
      width: 100% !important;
      max-width: none !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1124px) {
    .container {
      padding-left: 40px !important;
      padding-right: 40px !important;
      width: 100% !important;
      max-width: none !important;
    }
  }
}

.buttonsMenu {
  position: relative;
  margin-left: 0;
}
