@import "../../Css/variables";
@import "../componenets/NavBarUser/NavBarUser";
@import "../componenets/SideBar/SideMenu.scss";
@import "../componenets/Home/Post/Post.scss";
//@import "../componenets/Home/Home.scss";
@import "../componenets/Main/Center/Center.scss";
@import "../componenets//Main/Derictor/DerictorCard/DerictorCard.scss";
@import "../componenets/ImageDiv/ImageDiv.scss";
@import "../componenets/Main/Main.scss";
@import "../componenets/AddNewBtn/AddNew.scss";
@import "../componenets/Card/Card.scss";
@import "../componenets/Home/BloodStatistic/Bloodst.scss";
@import "../componenets/Search/Search.scss";
@import "../componenets/Filter/FilterCard/FilterCard.scss";
@import "../componenets/Filter/Filter.scss";
@import "../componenets/Comm/Comm.scss";
@import "../componenets/AddCenterForm/AddCenterForm.scss";
@import "../componenets/AddDerictorForm/AddDerictorForm.scss";

//media for sidebar column
@media (max-width: 991px) {
  .col-lg-0-5 {
    width: 0 !important;
    padding: 0 !important;
  }

  .col-lg-11-5 {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .main-container {
    margin-left: 0 !important;
    padding-top: 5.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .row {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mainParentRow {
    padding-top: 48px !important;
    padding-bottom: 90px !important;
  }
}

@media (min-width: 992px) {
  .col-lg-0-5 {
    width: 80px !important;
  }

  .main-container {
    margin-left: $margin-8-perce !important;
    padding-top: 5.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .col-lg-11-5 {
    width: calc(100% - 80px) !important;
  }

  .mainParentRow {
    padding-top: 60px !important;
  }
}

.bMain {
  margin-top: $nav-height !important;

  padding-left: 0 !important;
}

//for search div
.mainSearch {
  width: 100%;
  margin: 40px auto;
}

//for ul
.ulCards {
  list-style: none;
  padding-left: 0 !important;
}
// style buttons
.btnCustom {
  min-width: 100px !important;
  background-color: $color-primary-1 !important;
  padding: 10px !important;
  color: #fff !important;
  border: none !important;

  &:hover {
    background-color: #3fd7e2 !important;
  }
}
